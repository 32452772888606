import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Logo from "../img/MeinAlarm24-logo.svg";
import PhonePicture from "../img/phone2_blue.svg";
import PriceCalculationButton from "./PriceCalculator/PriceCalculationButton";
import CityFinder from "../helper/CityFinder";
import QueryString from "../helper/QueryString";

function Navbar({ context }) {
    const [screenWidth, setScreenWith] = useState();

    let queryString = new QueryString();
    let specialUrl = queryString.getSpecial();
    let cityFinder = new CityFinder(context.city);
    let city = cityFinder.getCity();
    let slug;
    let homeUrl;
    let slugString = "/";
    let priceCalcPrefix;
    let phone = "0800 150 99 00";
    let phone_tec = "tel://+498001509900";
    let videoueberwachung = "Videoüberwachung";
    let showCTA = true;
    // useEffect(() => {
    //     if (typeof window != "undefined") {
    //         console.log("window.screen.width : ", window.screen.width);
    //         setScreenWith(window.screen.width);
    //     }
    // }, []);

    function getWindowDimensions() {
        if (typeof window != "undefined") {
            return window.screen.width;
        }
    }

    // export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState();

    useEffect(() => {
        if (typeof window != "undefined") {
            function handleResize() {
                setWindowDimensions(window.screen.width);
                console.log("windowDimensions", windowDimensions);
            }

            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);

    if (typeof window != "undefined") {
        showCTA = !(
            window.location.pathname.includes("fieber-kameras") ||
            window.location.pathname.includes("einlasskontrolle")
        );
    }

    // if (
    //     typeof window != "undefined" &&
    //     (window.innerWidth > 1600 || window.innerWidth < 1088)
    // ) {
    //     videoueberwachung = "Videoüberwachung";
    // }

    let showSchliessanlage = false;
    let videogen = false;
    if (typeof window != "undefined" && window.innerWidth > 1735) {
        showSchliessanlage = true;
    } else {
        showSchliessanlage = false;
    }
    let einb = false;
    if (typeof window != "undefined" && window.innerWidth > 1275) {
        einb = true;
    } else {
        einb = false;
    }
    let showUbur = false;
    if (typeof window != "undefined" && window.innerWidth > 1140) {
        console.log("window.innerWidth", window.innerWidth);
        showUbur = true;
    } else {
        showUbur = false;
    }

    if (typeof window != "undefined" && window.innerWidth > 1585) {
        videogen = true;
    } else {
        videogen = false;
    }

    if (typeof city.slug !== "undefined") {
        slug = city.slug;
        slugString = slug == "" ? "/" : "/" + slug + "/";
        phone = city.fon;
        phone_tec = "tel://" + city.fon_tec;
    }

    // url prefix empty / city / special
    if (specialUrl) {
        homeUrl = "/" + specialUrl + "/";
        slugString = "/";
        priceCalcPrefix = "/" + specialUrl + "/";
    } else {
        homeUrl = slugString;
        priceCalcPrefix = homeUrl === "/" ? "/" : "/" + slug + "/";
    }

    return (
        <nav className="menu navbar is-transparent is-fixed-top">
            <div className="container">
                <div className="navbar-brand">
                    <a
                        className="navbar-burger navbar-burger-left"
                        aria-label="menu"
                        aria-expanded="false"
                        role="navigation"
                        onClick={() =>
                            document
                                .getElementById("nav-menu")
                                .classList.toggle("is-active")
                        }
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                    <div
                        className="level is-fullwidth is-mobile"
                        onClick={() =>
                            document
                                .getElementById("nav-menu")
                                .classList.remove("is-active")
                        }
                    >
                        <Link to={homeUrl} className="navbar-item level-left">
                            <figure className="image logo">
                                <Logo />
                            </figure>
                        </Link>
                        <a
                            href={phone_tec}
                            id="btn_navbar_phone"
                            className="navbar-item is-hidden-desktop level-right"
                        >
                            <figure className="image phone">
                                <PhonePicture />
                            </figure>
                        </a>
                    </div>
                </div>
                <div
                    id="nav-menu"
                    className="navbar-menu"
                    onClick={() =>
                        document
                            .getElementById("nav-menu")
                            .classList.remove("is-active")
                    }
                >
                    <div className="navbar-start">
                        <div className="navbar-item has-dropdown is-hoverable">
                            <div className="navbar-link is-arrowless">
                                <Link
                                    className="navbar-item"
                                    to={slugString + "alarmanlagen/"}
                                >
                                    Alarmanlagen
                                </Link>
                            </div>
                            <div className="navbar-dropdown is-boxed ">
                                <Link
                                    className="navbar-item"
                                    to="/alarmanlagen-haus"
                                >
                                    Alarmanlagen für Häuser
                                </Link>
                                <Link
                                    className="navbar-item"
                                    to="/alarmanlagen-firma"
                                >
                                    Alarmanlagen für Firmen
                                </Link>
                            </div>
                        </div>

                        {videogen && (
                            <Link
                                className="navbar-item"
                                to={slugString + "videoueberwachung/"}
                            >
                                {videoueberwachung}
                            </Link>
                        )}

                        {showSchliessanlage && (
                            <Link
                                className="navbar-item"
                                to={slugString + "schliessanlagen/"}
                            >
                                Schließanlagen
                            </Link>
                        )}
                        <div className="navbar-item has-dropdown is-hoverable">
                            <div className="navbar-link is-arrowless">
                                Weitere Produkte
                            </div>
                            <div className="navbar-dropdown is-boxed ">
                                {!showSchliessanlage && (
                                    <Link
                                        className="navbar-item"
                                        to={slugString + "schliessanlagen/"}
                                    >
                                        Schließanlagen
                                    </Link>
                                )}

                                {!videogen && (
                                    <Link
                                        className="navbar-item"
                                        to={slugString + "videoueberwachung/"}
                                    >
                                        {videoueberwachung}
                                    </Link>
                                )}

                                <Link
                                    className="navbar-item"
                                    to={slugString + "tuerschloesser/"}
                                >
                                    Elektronische Türschlösser
                                </Link>
                                <Link
                                    className="navbar-item"
                                    to={slugString + "brandmeldeanlagen/"}
                                >
                                    Brandmeldeanlagen
                                </Link>
                                <Link
                                    className="navbar-item"
                                    to={slugString + "fieber-kameras/"}
                                >
                                    Fieber-Kameras
                                </Link>

                                <Link
                                    className="navbar-item"
                                    to={slugString + "einlasskontrolle/"}
                                >
                                    Einlasskontrollsysteme
                                </Link>
                            </div>
                        </div>
                        <Link className="navbar-item " to="/ratgeber/">
                            Ratgeber
                        </Link>

                        {einb && (
                            <Link className="navbar-item" to="/einbruchsatlas/">
                                Einbruchsatlas
                            </Link>
                        )}
                        {showUbur && (
                            <Link className="navbar-item " to="/ueber-uns/">
                                Über uns
                            </Link>
                        )}
                        {/* <Link
                            className="navbar-item"
                            to={slugString + "kooperationspartner/"}
                        >
                            kooperationspartner
                        </Link> */}
                    </div>
                    <div className="navbar-end is-hidden-touch">
                        {showCTA && (
                            <PriceCalculationButton
                                city={slug}
                                product={context.product}
                                urlPrefix={priceCalcPrefix}
                                id="btn_navbar_preisberechnen"
                            />
                        )}
                        <div className="navbar-beratung">
                            <div className="navbar-beratung-title">
                                kostenfreie Beratung
                            </div>
                            <div className="navbar-beratung-content">
                                <a
                                    id="btn_navbar_phone"
                                    href={phone_tec}
                                    className="navbar-beratung-tel"
                                >
                                    {phone}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
