export const Alarmanlage = 'Einbruch';
export const Videoueberwachung = 'Video';
export const BeidesInteressant = 'Einbruch und Video';
export const WeissNicht = 'Weiss Nicht';
export const Wohnung = 'Wohnung';
export const Haus = 'Haus';
export const Gewerbe = 'Gewerbe';
export const Buero = 'Büro';
export const Privat = 'Privat';
export const Behoerde = 'Behörde';
export const Andere = 'Andere Immobilie';
export const Brand = 'Brand';
export const Tuerschloss = 'Schließanlage';
export const Fieberkamera = 'Fieberkamera';
export const Einlasskontrolle = 'Einlasskontrolle';

