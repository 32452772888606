import React from "react";
import { Link } from "gatsby";
import QueryString from "../../helper/QueryString";
import {
    Alarmanlage,
    Videoueberwachung,
    Brand,
    Tuerschloss,
} from "./elements/Constants";

const PriceCalculationButton = ({
    text,
    city,
    value,
    name,
    urlPrefix,
    product,
    id,
}) => {
    let link = "/preisrechner/";
    let cityPrefix = city ? "/" + city : "";
    if (urlPrefix) {
        link = urlPrefix + "preisrechner/";
    } else if (typeof city != "undefined" && city != "") {
        link = cityPrefix + "/preisrechner/";
    }
    switch (product) {
        case Alarmanlage:
            link = cityPrefix + "/alarmanlagen/preisrechner";
            id = "btn_bighero_preisberechnen";
            break;
        case Videoueberwachung:
            link = cityPrefix + "/videoueberwachung/preisrechner";
            id = "btn_bighero_preisberechnen";
            break;
        case Tuerschloss:
            link = cityPrefix + "/schliessanlagen/preisrechner";
            id = "btn_bighero_preisberechnen";
            break;
        case Brand:
            link = "/brandmeldeanlagen/preisrechner";
            break;
        case "Intrusion":
            link = "/risikoeinschaetzung/";
            break;
        default:
            break;
    }

    return (
        <Link
            id={id}
            className="button preisrechner-btn preisrechner-btn-primary"
            to={link}
            onClick={() => {
                if (typeof value !== "undefined") {
                    let query = new QueryString();
                    query.setValue(name, value);
                }
            }}
        >
            {typeof text === "undefined" ? "Kostenlos Preis berechnen " : text}
        </Link>
    );
};

export default PriceCalculationButton;
