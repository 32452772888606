export default class CityFinder {
    constructor(city) {
        this.city = city;
    }

    /**
     * Return the City or empty Object
     */
    getCity() {
        if (typeof this.city === 'undefined') {
            if (typeof sessionStorage !== 'undefined') {
                this.city = sessionStorage.getItem('city');
            } else {
                this.city = null;
            }
            if (this.city === null) {
                this.city = {};
            } else {
                this.city = JSON.parse(this.city);
            }
        }

        return this.city;
    }
}
