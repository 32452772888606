import { isObject } from "util";
import Cookies from "js-cookie";
import QueryStringParser from "query-string-parser";

export default class QueryString {
    constructor() {
        this.readQuery();
    }

    readQuery() {
        this.query = Cookies.getJSON("query");
        if (!isObject(this.query)) {
            this.query = {};
        }

        return this.query;
    }

    saveQuery(query) {
        this.query = query;
        Cookies.set("query", this.query, { expires: 90 });
    }

    parseQuery(search) {
        let obj = QueryStringParser.fromQuery(search);
        this.saveQuery(Object.assign(this.query, obj));
    }

    setValue(key, value) {
        value = this.cleanValue(value);
        this.query = this.readQuery();
        let obj = {};
        obj[key] = value;
        this.saveQuery(Object.assign(this.query, obj));
    }

    getQuery() {
        return this.query;
    }

    setCity(city) {
        if (typeof sessionStorage !== "undefined") {
            sessionStorage.setItem("city", JSON.stringify(city));
        }
        this.setValue("region", city.key);
        this.setValue("regionSlug", city.slug);
    }

    setSpecial(value) {
        value = this.cleanValue(value);
        if (typeof sessionStorage !== "undefined") {
            sessionStorage.setItem("special", value);
        }
        this.setValue("special", value);
    }

    getSpecial() {
        let special = "";
        if (typeof sessionStorage !== "undefined") {
            let value = sessionStorage.getItem("special");
            if (value === null) {
                return special;
            }

            return value;
        }

        return special;
    }

    cleanValue(value) {
        if (typeof value === "undefined") {
            value = "";
        }

        return value;
    }
}
