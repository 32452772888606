import React from 'react';
// import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '../styles/index.scss';
import Header from '../components/Header';
import { StaticQuery, graphql } from "gatsby";

const TemplateWrapper = ({ children }) => (
    <StaticQuery
        query={graphql`
        query LayoutQuery {
        site {
          siteMetadata {
            title
            keywords
            description
            headerImage
            ogTitle
            ogDescription
          }
        }
      }`}
      render={data => (
        <div className="Application is-fullheight">
            <Header page={data.site.siteMetadata} siteMetadata={data.site.siteMetadata} contextData={{}} />
            {children}
        </div>
        )}
    />
);

TemplateWrapper.propTypes = {

};

export default TemplateWrapper;
