import QueryString from "./src/helper/QueryString";
// require("./src/styles/index.scss");

export const onRouteUpdate = () => {
    let query = new QueryString();

    if (typeof window !== "undefined") {
        query.parseQuery(window.location.search);
        if (!query.getQuery().originalLandingPage) {
            query.setValue("originalLandingPage", window.location.href);
        }
    }

    if (
        typeof document !== "undefined" &&
        typeof document.referrer !== "undefined" &&
        document.referrer !== ""
    ) {
        query.setValue("referrer", document.referrer);
    }
};
