exports.onRouteUpdate = function (_ref) {
    var auorg = document.getElementById("auorg-bg");

    // let scripts = Array.from(document.querySelectorAll("script")).map(
    //     (scr) => scr.src
    // );

    // if (
    //     !scripts.includes(
    //         "//siegel.ausgezeichnet.org/widgets/js/5ab4e3cd4c741772b64b09d2/reviewSlider.js?global=true&useEnglish=false"
    //     )
    // ) {
    //     setTimeout(() => {
    //         const script2 = document.createElement("script");
    //         script2.id = "reviewsId";
    //         script2.src =
    //             "//siegel.ausgezeichnet.org/widgets/js/5ab4e3cd4c741772b64b09d2/reviewSlider.js?global=true&useEnglish=false";
    //         script2.async = true;
    //         document.body.appendChild(script2);
    //     }, 3000);
    // }
    // const reviewsSc = document.getElementById("reviewsId");
    // if (reviewsSc) {
    //     console.log(
    //         "reviewsSc founddddddddddddddddddddddddddddd-------------"
    //     );
    //     document.body.removeChild(reviewsSc);
    // }
    // setTimeout(() => {
    //     const script2 = document.createElement("script");
    //     script2.id = "reviewsId";
    //     script2.src =
    //         "https://siegel.ausgezeichnet.org/widgets/js/5ab4e3cd4c741772b64b09d2/reviewSlider.js?global=true&useEnglish=false";
    //     script2.async = true;
    //     document.body.appendChild(script2);
    // }, 2000);

    if (auorg == null) {
        return;
    }
    var script = document.createElement("script");
    script.src =
        "//siegel.ausgezeichnet.org/widgets/js/5ab4e3cd4c741772b64b09d2/widget.js";
    auorg.parentNode.appendChild(script);
};
